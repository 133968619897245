import { useEffect, useState, useContext, ReactNode } from 'react';

import { useJwtDecoder, useCookie, IGeladaAccessTokenPayload } from '@netfront/common-library';
import { IGeladaOrganisation, IGeladaProject } from '@netfront/gelada-identity-library';
import { Dropdown, OrganisationIcon, OrganisationNavigation, OrganisationNavigationItemProps, SettingsIcon, SwitchIcon, TeamIcon, UsersIcon } from '@netfront/ui-library';
import Link from 'next/link';

import { OrganisationMenuProps } from './OrganisationMenu.interfaces';

import { CachingEntitiesContext, PermissionContext } from '../../../context';
import { UpsertOrganisationSidebar } from '../../Sidebars/UpsertOrganisationSidebar';

const OrganisationMenu = ({isInternal = false, onDeleteOrganisation, onUpdateOrganisation, organisation, onDeleteOrganisationAsset }: OrganisationMenuProps) => {

  const { getDecodedJwt } = useJwtDecoder();
  const { hasPermission } = useContext(PermissionContext);
  const { getAccessTokenCookie } = useCookie();


  const { project, dashboardUrl } = useContext(CachingEntitiesContext);

  const [isCustomBuild, setIsCustomBuild] = useState<boolean>(false);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [navigationList, setNavigationList] = useState<OrganisationNavigationItemProps[]>([]);
  const [isOrganisationPanelOpen, setIsOrganisationPanelOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState<IGeladaProject>();
  const [currentOrganisation, setCurrentOrganisation] = useState<IGeladaOrganisation>();
  const [hasEditPermission, setHasEditPermission] = useState<boolean>(false);

  const handleUpdateOrganisation = (returnedOrganisation: IGeladaOrganisation) => {
    if (currentProject) setCurrentProject({
      ...currentProject,
      organisation: returnedOrganisation,
    } as IGeladaProject);

    setCurrentOrganisation(returnedOrganisation);
    setIsOrganisationPanelOpen(false);
    if (onUpdateOrganisation) onUpdateOrganisation(returnedOrganisation);
  };

  const handleDeleteOrganisation = (organisationKey: string) => {
    setIsOrganisationPanelOpen(false);
    if (onDeleteOrganisation) onDeleteOrganisation(organisationKey);
  }

  const handleToggleOrganisationPanel = () => {
    setSearchIsContentVisible(false);
    setIsOrganisationPanelOpen(true);
  };

  useEffect(() => {
    if (!(dashboardUrl && project) && !isInternal) {
      return;
    }

    setCurrentProject(project);

  }, [dashboardUrl, project, isInternal]);

  useEffect(() => {
    if (isInternal && organisation) {
      setCurrentOrganisation(organisation);
      return;
    }

    if (!currentProject) return;

    setCurrentOrganisation(currentProject.organisation);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject, isInternal, currentOrganisation, organisation]);

  useEffect(() => {

    if (!currentOrganisation) return;

    setHasEditPermission(hasPermission(String(currentOrganisation.id), 'EDIT'));

    const dropdownNavigationItems: OrganisationNavigationItemProps[] = [
      {
        isHidden: !hasPermission(String(currentOrganisation.id), 'EDIT') && !hasPermission(String(currentOrganisation.id), 'MANAGE_USERS'),
        header: {
          title: `Current account`,
          name: currentOrganisation.name,
          icon: SwitchIcon,
          wrapperComponent: !isCustomBuild ? ({children} : { children: ReactNode}) => <Link href="/dashboard" target="_self">{children}</Link>: undefined
        },
        options: [
          {
            icon: SettingsIcon,
            label: 'Settings',
            isHidden: !hasPermission(String(currentOrganisation.id), 'EDIT'),
            onClick: handleToggleOrganisationPanel,
          },
          {
            icon: TeamIcon,
            label: 'Team',
            isHidden: !hasPermission(String(currentOrganisation.id), 'MANAGE_USERS'),
            wrapperComponent: ({children} : { children: ReactNode}) => <Link href={`/account/${String(currentOrganisation.key)}/team`} target="_self">{children}</Link>,
          },
        ],
      },
      {
        isHidden: !currentProject,
        header: {
          title: `Current project`,
          name: currentProject?.name ?? '',
          icon: SwitchIcon,
          wrapperComponent: !isCustomBuild ? ({children} : { children: ReactNode}) => <Link href="/dashboard" target="_self">{children}</Link>: undefined
        },
        options: [
          {
            icon: UsersIcon,
            label: 'Users',
            isHidden: !currentProject,
            wrapperComponent: ({children} : { children: ReactNode}) => <Link href={`/project/${String(currentProject?.id ?? '')}/user`} target="_self">{children}</Link>
          },
        ],
      },
    ];

    setNavigationList(dropdownNavigationItems);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganisation]);

  useEffect(() => {
    const accessToken = getAccessTokenCookie();

    if (!accessToken) {
      return;
    }

    const decoded = getDecodedJwt(accessToken) as IGeladaAccessTokenPayload;
    const claim = decoded.custom_build;

    setIsCustomBuild(Boolean(String(claim).toLowerCase() === 'true'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (Boolean(project) || (organisation && isInternal)) return (
    <>
      {
        hasEditPermission && currentOrganisation && (
          <UpsertOrganisationSidebar
            handleOpenCloseSideBar={() => setIsOrganisationPanelOpen(false)}
            isSideBarOpen={isOrganisationPanelOpen}
            prefix="organisation_menu"
            selectedOrganisation={currentOrganisation}
            onDeleteAsset={onDeleteOrganisationAsset}
            onDeleteOrganisation={handleDeleteOrganisation}
            onUpdateOrganisation={handleUpdateOrganisation}
          />
        )
      }

      <div className="c-organisation-menu">
        <Dropdown
          dropdownId="organisation-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<><OrganisationIcon className="c-app-header__item" /><span className="h-hide-visually">Toggle organisation menu</span></>}
          onDisplayContent={setSearchIsContentVisible}
        >
          <OrganisationNavigation
            dropdownNavigationItems={navigationList}
          />
        </Dropdown>
      </div>
    </>
  );

  return <></>;
};

export { OrganisationMenu };
