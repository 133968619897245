import React, { ChangeEvent, useEffect, useState } from 'react';

import { Button, FlexContainer, MfaCodeInput, Spacing } from '@netfront/ui-library';

import { ConfirmMfaCodeFormProps } from './ConfirmMfaCodeForm.interfaces';

const ConfirmMfaCodeForm = ({ buttonText = 'Login', isSubmitting, onSubmitCode, onReturnToLoginClick }: ConfirmMfaCodeFormProps) => {
  const CODE_LENGTH = 6;
  const [code, setCode] = useState<string>('');

  const handleClick = () => {
    if (code.length !== CODE_LENGTH) return;
    onSubmitCode(code);
  };

  useEffect(() => {
    if (code.length !== CODE_LENGTH) return;
    onSubmitCode(code);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  return (
    <FlexContainer flexDirection="column" justifyContent="center">
      <Spacing size="2x-large">
        <p className="c-confirm-mfa-code__label">
          Enter the <strong>{CODE_LENGTH}-digit</strong> code you see in the app
        </p>
      </Spacing>

      <MfaCodeInput onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setCode(value)} />
      <Spacing size="2x-large">
        <p className="c-confirm-mfa-code__text">
          Return to{' '}
          <button className="c-confirm-mfa-code__button" type="button" onClick={onReturnToLoginClick}>
            Login
          </button>
        </p>
      </Spacing>
      <Button isDisabled={isSubmitting || code.length !== CODE_LENGTH} text={buttonText} type="button" onClick={handleClick} />
    </FlexContainer>
  );
};

export { ConfirmMfaCodeForm };
