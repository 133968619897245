import { CloseIcon, TickIcon } from '@netfront/ui-library';
import cx from 'classnames';

import { PasswordCriteriaProps } from './PasswordCriteria.interfaces';

const PasswordCriteria = ({criteria, label}: PasswordCriteriaProps) => {
  return (
    <div className="c-password-criteria">
      <p className="c-password-criteria__text">{label}</p>
      <ul className="c-password-criteria__list">
        {
          Object.keys(criteria).map((key) => (
            <li key={key} className="c-password-criteria__item">
              <div className={cx('c-password-criteria__icon', { 'is-invalid': !criteria[key].isValid })}>
                {
                  criteria[key].isValid
                  ? <TickIcon className="c-icon" />
                  : <CloseIcon className="c-icon" />
                }
              </div>
              <span>{criteria[key].label}</span>
            </li>
          ))
        }
      </ul>
    </div>
  );
}

export { PasswordCriteria };
