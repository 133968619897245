import { useContext, useEffect, useState } from 'react';

import { useAuthentication, useDomain, useGetProduct } from '@netfront/gelada-identity-library';
import { AppHeader, AppHeaderProps, EkardoIcon, Footer, PageLayoutTemplate, Spinner } from '@netfront/ui-library';
import cx from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { OrganisationMenu, UserAccountMenu } from 'components/Menus';

import { IMeta, PageLayoutProps } from './PageLayout.interfaces';

import { INTERNAL_URLS } from '../../../constants';
import { CachingEntitiesContext } from '../../../context';

const PageLayout: React.FC<PageLayoutProps> = ({
  additionalClassNames,
  children,
  hasPrivateLayout,
  isDisplayLayoutTemplate = true,
  isInternal = false,
  isPreloaderVisible = false,
  isProtectedPage = false,
  meta,
  onDeleteOrganisation,
  onDeleteOrganisationAsset,
  onUpdateOrganisation,
  organisation,
  title,
}) => {
  const { isAuthenticated } = useAuthentication();
  const { isDomainReady } = useDomain();
  const { getProduct } = useGetProduct();
  const { push } = useRouter();

  const [faviconUrl, setFaviconUrl] = useState<string>();
  const [footerTitle, setFooterTitle] = useState<string>();
  const [logoConfig, setLogoConfig] = useState<AppHeaderProps['logo']>();

  const projectName = getProduct();

  useEffect(() => {
    if (!isDomainReady) return;

    if (isAuthenticated || !isProtectedPage) return;

    void push(INTERNAL_URLS.login);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const { externalUrl, project } = useContext(CachingEntitiesContext);

  const { description = 'Description' } = meta ?? ({} as IMeta);

  useEffect(() => {
    if (isInternal && projectName) {
      setLogoConfig({
        href: INTERNAL_URLS.dashboard,
        icon: EkardoIcon,
        imageSrc: `/images/${projectName.toLowerCase()}-logo-full.svg`,
      });
      setFooterTitle(projectName);
      setFaviconUrl(projectName.toLowerCase());
      return;
    }

    if (!(project && externalUrl)) return;

    setLogoConfig({
      href: `${String(externalUrl)}/dashboard/${String(project.organisation.key)}/${String(project.id)}`,
      icon: EkardoIcon,
      imageSrc: project.logo?.presignedUrl,
    });
    setFooterTitle(project.name);
    setFaviconUrl('ekardo');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInternal, projectName, organisation, project, externalUrl]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta content={description} name="description" />
        {faviconUrl && <link href={`/${faviconUrl}.ico`} rel="icon" type="image/x-icon" />}
      </Head>

      {isDisplayLayoutTemplate ? (
        <PageLayoutTemplate
          additionalClassNames={cx('c-page-layout-template', additionalClassNames)}
          footer={<Footer projectName={footerTitle} />}
          header={
            <AppHeader logo={logoConfig}>
              {hasPrivateLayout && (
                <>
                  <OrganisationMenu
                    organisation={organisation}
                    isInternal
                    onDeleteOrganisation={onDeleteOrganisation}
                    onDeleteOrganisationAsset={onDeleteOrganisationAsset}
                    onUpdateOrganisation={onUpdateOrganisation}
                  />
                  <UserAccountMenu isInternal={isInternal} />
                </>
              )}
            </AppHeader>
          }
        >
          <Spinner isLoading={isPreloaderVisible} />

          <main className="c-page-layout-template__main">{children}</main>
        </PageLayoutTemplate>
      ) : (
        children
      )}
    </>
  );
};

export { PageLayout };
