import { ILoginFormFields } from './LoginForm.interfaces';

const LOGIN_FORM_CSS_IDENTIFIERS = Object.freeze({
  block: 'c-login-form',
});

const LOGIN_FORM_INITIAL_VALUES: ILoginFormFields = {
  email: '',
  password: '',
};

export { LOGIN_FORM_CSS_IDENTIFIERS, LOGIN_FORM_INITIAL_VALUES };
