const LOGIN_PAGE_CSS_IDENTIFIERS = Object.freeze({
  BLOCK: 'c-login-page',
});

const LOGIN_PAGE_DEFAULTS = Object.freeze({
  AUTHENTICATE: 'Authenticate',
  HEADER_TEXT: 'Login',
  PAGE_DESCRIPTION: 'Login to continue',
});

const LOGIN_PAGE_CONSTANTS = Object.freeze({
  CSS_IDENTIFIERS: LOGIN_PAGE_CSS_IDENTIFIERS,
  DEFAULTS: LOGIN_PAGE_DEFAULTS,
});

export { LOGIN_PAGE_CONSTANTS };
