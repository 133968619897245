import { IPasswordCriteria } from './PasswordCriteria.interfaces';

export const isPasswordInvalid = (validationObject: IPasswordCriteria): boolean => {
  for (const key in validationObject) {
    if (!validationObject[key].isValid) {
      return true;
    }
  }

  return false;
};

export const getPasswordValidationState = (validationObject: IPasswordCriteria, value: string): IPasswordCriteria => {
  const lowercaseRegex = /[a-z]/;
  const specialCharRegex = /[^a-zA-Z0-9]/;
  const uppercaseRegex = /[A-Z]/;

  const { length, lowercase, special, uppercase } = validationObject;

  return {
    length: { ...length, isValid: value.length >= 8 },
    lowercase: { ...lowercase, isValid: lowercaseRegex.test(value) },
    special: { ...special, isValid: specialCharRegex.test(value) },
    uppercase: { ...uppercase, isValid: uppercaseRegex.test(value) },
  };
};
