import { useEffect, useState, useContext } from 'react';

import { useCookie } from '@netfront/common-library';
import { IUser, useDomain, useIdentitySiteUrls, useUser } from '@netfront/gelada-identity-library';
import { Dropdown, EnterIcon, HelpDocsIcon, IDropdownNavigationItem, IntegrationIcon, LogoAvatar, LogoutIcon, PartnersIcon, PlansIcon, UserNavigation, UserNavigationItemProps, UsersIcon } from '@netfront/ui-library';
import { useRouter } from 'next/router';
import { useUserIsAdmin } from 'utils';

import { INTERNAL_URLS } from '../../../constants';
import { CachingEntitiesContext, UserContext } from '../../../context';

const UserAccountMenu = ({isInternal = false}: {isInternal?: boolean}) => {
  const { getOrganisationIdCookie } = useCookie();

  const { dashboardUrl, product } = useContext(CachingEntitiesContext);
  const { profileImageUrl, fullName, email, hasPartner = false } = useContext(UserContext);

  const {
    query: { projectId: queryProjectId },
  } = useRouter();


  const { getUser } = useUser();
  const { isDomainReady, getDomain } = useDomain();
  const { isAdmin } = useUserIsAdmin();
  const { getLogoutUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [projectId, setProjectId] = useState<string>('');
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [footerConfig, setFooterConfig] = useState<IDropdownNavigationItem>();
  const [navigationList, setNavigationList] = useState<UserNavigationItemProps[]>([]);
  const [loggedUser, setLoggedUser] = useState<IUser>();
  const [organisationId, setOrganisationId] = useState<string>();
  const [administrationUrl, setAdministrationUrl] = useState<string>();
  const [partnerUrl, setPartnerUrl] = useState<string>();


  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    const url = getLogoutUrl();

    setLogoutUrl(url);
    setLoggedUser(getUser());
    setOrganisationId(getOrganisationIdCookie());
    setAdministrationUrl(`https://partners.${getDomain()}/management/springboard`);
    setPartnerUrl(`https://partners.${getDomain()}/dashboard`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!(dashboardUrl && organisationId && (projectId || isInternal))) {
      return;
    }

    const [baseUrl] = dashboardUrl.split('/dashboard');

    const dropdownNavigationItems: UserNavigationItemProps[] = [
      {
        header: {
          title: String(fullName),
          name: String(email),
          icon: EnterIcon,
          href: String(INTERNAL_URLS.profile),
          linkText: 'View profile'
        },
        options: [
          {
            icon: PlansIcon,
            href: `${dashboardUrl}/${String(organisationId)}/${String(projectId)}/payments`,
            label: 'Plans',
            isHidden: isInternal || !projectId || product === 'EKARDO',
          },
          {
            icon: IntegrationIcon,
            href: 'https://calendly.com/greg_stephenson',
            label: 'Book demo',
            target: '_blank'
          },
          {
            icon: HelpDocsIcon,
            href: `${baseUrl}/docs`,
            label: 'Help docs',
            isHidden: !baseUrl
          },
          {
            icon: PartnersIcon,
            href: partnerUrl,
            label: 'Partner',
            isHidden: !hasPartner,
          },
          {
            icon: UsersIcon,
            href: administrationUrl,
            label: 'Administration',
            isHidden: !isAdmin,
          },
        ],
      },
    ];

    setNavigationList(dropdownNavigationItems);

    setFooterConfig({
      label: 'Logout',
      href: logoutUrl,
      icon: LogoutIcon,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardUrl, organisationId, projectId, product, administrationUrl, partnerUrl, isAdmin, hasPartner]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);


  if (loggedUser) return (
    <div className="c-user-account-menu">
      <Dropdown
        dropdownId="profile-dropdown"
        isDisplayContent={isSearchContentVisible}
        trigger={<LogoAvatar avatarImageSrc={String(profileImageUrl)} size="small" title={String(fullName)} />}
        onDisplayContent={setSearchIsContentVisible}
      >
        <UserNavigation
          dropdownNavigationItems={navigationList}
          footerConfig={footerConfig}
        />
      </Dropdown>
    </div>
  );

  return <></>;
};

export { UserAccountMenu };
