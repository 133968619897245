import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { getBEMClassName } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS } from '@netfront/gelada-identity-library';
import { Button, Input } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { LocalDomainSelector } from 'components/Shared';

import { PRELOGIN_FORM_CSS_IDENTIFIERS, PRELOGIN_FORM_INITIAL_VALUES } from './PreLoginForm.constants';
import { IPreLoginFormFields, PreLoginFormProps } from './PreLoginForm.interfaces';

import { getFormFieldErrorMessage } from '../../Forms.helpers';

const PreLoginForm = ({
  buttonText = 'Next',
  hasLocalHostDomainSelector = false,
  isSubmitting,
  onLocalHostDomainChange,
  onSubmit,
}: PreLoginFormProps) => {
  const { button: buttonElementCssId, container: containerElementCssId, form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    email: { id: emailId, label: emailLabel, validationErrorMessage: emailValidationErrorMessage },
  } = FORM_FIELDS;

  const { block: blockCssId } = PRELOGIN_FORM_CSS_IDENTIFIERS;


  const { control, handleSubmit } = useForm<IPreLoginFormFields>({
    defaultValues: PRELOGIN_FORM_INITIAL_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().label(emailLabel).email(emailValidationErrorMessage).required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  return (
    <form
      className={getBEMClassName(blockCssId, formElementCssId)}
      onSubmit={(event) => {
        void handleSubmit(({ email }) => {
          onSubmit({
            email,
          });
        })(event);
      }}
    >
      <div className="c-form__body">
        <LocalDomainSelector isVisible={hasLocalHostDomainSelector} onChange={onLocalHostDomainChange} />

        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <Input
              additionalClassNames={getBEMClassName(blockCssId, emailId)}
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id={emailId}
              isDisabled={isSubmitting}
              labelText={emailLabel}
              placeholder=""
              type="email"
              isRequired
              {...field}
            />
          )}
        />
      </div>

      <div className="c-form__footer">
        <div className={getBEMClassName(blockCssId, `${buttonElementCssId}s-${containerElementCssId}`)}>
          <Button size="small" text={buttonText} type="submit" />
        </div>
      </div>
    </form>
  );
};

// eslint-disable-next-line import/no-default-export
export { PreLoginForm };
