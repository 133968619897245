import React, { useEffect, useState } from 'react';

import { useCookie } from '@netfront/common-library';
import { useDomain, useGetProduct } from '@netfront/gelada-identity-library';
import { FlexContainer, Spinner } from '@netfront/ui-library';
import cx from 'classnames';
import Head from 'next/head';
import NextLink from 'next/link';

import { Copyright } from '../Copyright';

import { LayoutProps } from './Layout.interfaces';

const Layout = ({
  children,
  headerText,
  isPreloaderVisible = false,
  pageDescription,
  shouldRerender = false,
  hasContentPadding = true,
  headerAddOn,
  dashboardUrl,
  hasSmallHeaderText = false,
  title,
}: LayoutProps) => {
  const { getProjectLogoUrlCookie, getRedirectAfterLoginUrlCookie } = useCookie();
  const { isDomainReady } = useDomain();
  const { isNetfrontProject: checkForNetfrontProject, getProduct } = useGetProduct();

  const [description, setDescription] = useState<string>(pageDescription ?? '');
  const [faviconUrl, setFaviconUrl] = useState<string>();
  const [isNetfrontProject, setIsNetfrontProject] = useState<boolean>(false);
  const [projectLogoUrl, setProjectLogoUrl] = useState<string>();
  const [projectName, setProjectName] = useState<string>();
  const [redirectAfterLoginUrl, setRedirectAfterLoginUrl] = useState<string>();

  useEffect(() => {
    if (!(isDomainReady && shouldRerender)) {
      return;
    }

    setDescription(pageDescription ?? '');
    setProjectLogoUrl(getProjectLogoUrlCookie());
    setRedirectAfterLoginUrl(getRedirectAfterLoginUrlCookie());
    setIsNetfrontProject(checkForNetfrontProject());
    const productName = getProduct();
    if (productName) {
      setFaviconUrl(productName.toLowerCase());
      setProjectName(productName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady, shouldRerender, getProduct]);

  useEffect(() => {
    setDescription(pageDescription ?? '');
  }, [pageDescription]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta content={description} name="description" />
        {faviconUrl && (
          <link href={`/${faviconUrl}.ico`} rel="icon" type="image/x-icon"></link>
        )}
      </Head>

      <div className="c-layout__background">
        <Spinner isLoading={isPreloaderVisible} />

        {children && (
          <div className="c-layout__content-container">
            <div className="c-layout__content-header">
              <div className="c-layout__content-header-product-logo">
                {projectLogoUrl && redirectAfterLoginUrl ? (
                  <>
                    <span className="h-hide-visually">Return to dashboard</span>

                    <NextLink href={decodeURIComponent(redirectAfterLoginUrl)}>
                      <span className="c-layout__content-header-product-logo__image">
                        <span className="h-hide-visually">Return to dashboard</span>
                        <img
                          alt=""
                          className="c-layout__content-header-product-logo__image__image"
                          src={decodeURIComponent(projectLogoUrl)}
                        />
                      </span>
                    </NextLink>
                  </>
                ) : (
                  <>
                    {isNetfrontProject && projectName && (
                      <img
                        alt={projectName}
                        className="c-layout__content-header-product-logo__image__image"
                        src={`/images/${projectName.toLowerCase()}-logo.png`}
                      />
                    )}
                  </>
                )}
              </div>
              <FlexContainer gap="medium">
                {headerAddOn}
                <h1
                  className={cx('c-layout__content-header-text', {
                    'is-small': hasSmallHeaderText,
                  })}
                >
                  {headerText}
                </h1>
              </FlexContainer>
              <p key={description} className="c-layout__content-header-description">
                {description}
              </p>
            </div>

            <div
              className={cx('c-layout__content-body', {
                'c-layout__content--padded': hasContentPadding,
              })}
            >
              {children}
            </div>

            <div className="c-layout__content-footer">
              {dashboardUrl && (
                <div className="c-return-home">
                  <a href={dashboardUrl}>Return to dashboard</a>
                </div>
              )}
              <Copyright />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { Layout };
