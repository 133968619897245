/* eslint-disable @typescript-eslint/naming-convention */
import { useContext, useEffect, useState } from 'react';

import { ICookieOptionalAttributesInput, useCookie } from '@netfront/common-library';
import {
  convertDBUser,
  DEFAULT_STORAGE_EXPIRY_OPTION,
  DEFAULT_STORAGE_EXPIRY_OPTIONS,
  LOCALHOST,
  useAuthentication,
  useDomain,
  useGetProduct,
  useIdentitySiteUrls,
  useLogin,
  useResendActivationCode,
} from '@netfront/gelada-identity-library';
import { Button, Dialog } from '@netfront/ui-library';
import isNil from 'lodash.isnil';
import { useRouter } from 'next/router';
import { getSingularOrgProjectRedirectUrl, useLoginRedirect } from 'utils';

import { LOGIN_PAGE_CONSTANTS } from './LoginPage.constants';
import { ILoginCredentials } from './LoginPage.interfaces';
import styles from './LoginPage.module.css';

import { MFA_CODE_REQUESTED, USER_NOT_ACTIVATED_MESSAGE } from '../../../constants';
import { CachingEntitiesContext, UserContext } from '../../../context';
import { useGetGeladaProjectByDomain, useToast } from '../../../hooks';
import { ConfirmMfaCodeForm } from '../../Forms/Authentication/ConfirmMfaCodeForm';
import { LoginForm } from '../../Forms/Authentication/LoginForm';
import { PreLoginForm } from '../../Forms/Authentication/PreLoginForm';
import { Layout } from '../../Shared/Layout';

const {
  CSS_IDENTIFIERS: { BLOCK: blockCssId },
  DEFAULTS: { HEADER_TEXT, AUTHENTICATE, PAGE_DESCRIPTION },
} = LOGIN_PAGE_CONSTANTS;

const LoginPage = () => {
  const { isAuthenticated: isAuthenticationDataSetup } = useAuthentication();

  const {
    createAccessTokenCookie,
    createCustomBuildProjectGuidCookie,
    createOrganisationIdCookie,
    createProjectDomainCookie,
    createProjectGuidCookie,
    createProjectLogoUrlCookie,
    createProjectNameCookie,
    createRedirectAfterLoginUrlCookie,
    createRefreshTokenCookie,
    createUserDataCookie,
    getCustomBuildProjectGuidCookie,
    getProjectGuidCookie,
    getRedirectAfterLoginUrlCookie,
    isSecureCookie,
  } = useCookie();
  const { getDomain, isDomainReady } = useDomain();
  const { getProduct } = useGetProduct();
  const { handleLoginRedirect } = useLoginRedirect();
  const { getForgotPasswordUrl, getRegistrationUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    push,
    query: { email: initialEmail },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { setStoredUser } = useContext(UserContext);
  const { dashboardUrl: storedDashboardUrl } = useContext(CachingEntitiesContext);

  const [dashboardUrl, setDashboardUrl] = useState<string>('');
  const [forgotPasswordUrl, setForgotPasswordUrl] = useState<string>();
  const [hasLocalHostDomainSelector, setHasLocalHostDomainSelector] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isConfirmMfaCodeVisible, setIsConfirmMfaCodeVisible] = useState<boolean>(false);
  const [isPreloginVisible, setIsPreloginVisible] = useState<boolean>(true);
  const [isProjectCustomBuild, setIsProjectCustomBuild] = useState<boolean>(false);
  const [isResendActivationCodeDialogOpen, setIsResendActivationCodeDialogOpen] = useState<boolean>(false);
  const [isUserAProjectAdmin, setIsUserAProjectAdmin] = useState<boolean>();
  const [loginCredentials, setLoginCredentials] = useState<ILoginCredentials>();
  const [projectWebsiteUrl, setProjectWebsiteUrl] = useState<string>();
  const [redirectIfSingularOrgProjectUrl, setRedirectIfSingularOrgProjectUrl] = useState<string>('');
  const [registrationUrl, setRegistrationUrl] = useState<string>();
  const [selectedLocalHostDomain, setSelectedLocalHostDomain] = useState<string>();

  useEffect(() => {
    setIsPreloginVisible(isNil(initialEmail));
  }, [initialEmail]);

  const { handleGetGeladaProjectByDomain, isLoading: isGetGeladaProjectByDomainLoading = false } = useGetGeladaProjectByDomain({
    fetchPolicy: 'no-cache',
    onCompleted: ({ geladaProject }) => {
      const { domain: projectDomain, id, isCustomBuild, logo, name, organisationId } = geladaProject;
      const { redirectAfterLogin, value: projectDomainValue, website } = projectDomain ?? {};
      const { presignedUrl } = logo ?? {};

      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      const optionalCookieAttributesInput: ICookieOptionalAttributesInput = {
        domain,
        secure: isSecure,
        expiryOptions: {
          storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTION,
        }
      };

      setProjectWebsiteUrl(website);

      if (isCustomBuild) {
        setIsProjectCustomBuild(isCustomBuild);

        createCustomBuildProjectGuidCookie({
          optionalCookieAttributesInput,
          value: id,
        });
      }

      createOrganisationIdCookie({
        optionalCookieAttributesInput,
        value: String(organisationId),
      });

      if (projectDomainValue) {
        createProjectDomainCookie({
          optionalCookieAttributesInput,
          value: projectDomainValue,
        });
      }

      createProjectGuidCookie({
        optionalCookieAttributesInput,
        value: id,
      });

      if (presignedUrl) {
        createProjectLogoUrlCookie({
          optionalCookieAttributesInput,
          value: encodeURIComponent(presignedUrl),
        });
      }

      createProjectNameCookie({
        optionalCookieAttributesInput,
        value: name,
      });

      if (redirectAfterLogin) {
        createRedirectAfterLoginUrlCookie({
          optionalCookieAttributesInput,
          value: encodeURIComponent(redirectAfterLogin),
        });
      }
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleLogin, isLoading: isLoginLoading = false } = useLogin({
    onCompleted: ({ accessToken, refreshToken, user }) => {
      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      if (accessToken) {
        // The createXXXCookie functions all validate that a cookie doesn't exceed the maximum cookie size
        // We only expect the access token to potentially exceed the maximum cookie size hence this is the
        // only function that will be wrapped inside a try/catch block

        try {
          createAccessTokenCookie({
            optionalCookieAttributesInput: {
              domain,
              secure: isSecure,
              expiryOptions: {
                storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.accessToken,
              }
            },
            value: accessToken,
          });
        } catch (error) {
          handleToastError({
            error: error as Error,
            shouldUseFriendlyErrorMessage: true,
          });

          return;
        }
      }

      if (refreshToken) {
        createRefreshTokenCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.refreshToken,
            }
          },
          value: refreshToken,
        });
      }

      if (user) {
        const updatedUser = convertDBUser(user);

        const {
          credential: { email },
          firstName,
          id,
          lastName,
          memberships,
          pictures,
        } = updatedUser;

        setStoredUser(updatedUser);

        const singleOrgAndProjectRedirectUrl = getSingularOrgProjectRedirectUrl(memberships);

        if (singleOrgAndProjectRedirectUrl) setRedirectIfSingularOrgProjectUrl(`${String(dashboardUrl)}/${singleOrgAndProjectRedirectUrl}`);

        const { configuration, originalPicture, profilePicture } = pictures ?? {};

        const projectGuid = getProjectGuidCookie();

        if (projectGuid) {
          const isProjectAdmin = memberships
            .filter((r) => r.project?.guid === projectGuid)
            .some((r) => r.type === 'PROJECT' && r.permission !== 'NONE');
          setIsUserAProjectAdmin(isProjectAdmin);
        }

        /*
         * Note
         *  - The whole user object is too large to store in a cookie
         *  - Extract only the important user data to store in a cookie
         */
        createUserDataCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.userData,
            }
          },
          value: JSON.stringify({
            email,
            firstName,
            id,
            lastName,
            pictures: {
              configuration,
              originalPicture: {
                presignedUrl: encodeURIComponent(originalPicture?.presignedUrl ?? ''),
              },
              profilePicture: {
                presignedUrl: encodeURIComponent(profilePicture?.presignedUrl ?? ''),
              },
            },
          }),
        });
      }

      setIsAuthenticated(true);
    },
    onError: (error) => {
      const { message } = error;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { networkError } = error as unknown as { networkError: any };

      let errorCode;
      if (networkError) {
        const {
          result: { errors },
        } = networkError;
        errorCode = errors[0].extensions.code;
      }

      if (errorCode === MFA_CODE_REQUESTED) {
        setIsConfirmMfaCodeVisible(true);
        return;
      }

      if (message === USER_NOT_ACTIVATED_MESSAGE) {
        setIsResendActivationCodeDialogOpen(true);
        return;
      }

      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product: getProduct(),
    projectId: getCustomBuildProjectGuidCookie(),
  });

  const { handleResendActivationCode, isLoading: isResendActivationCodeLoading = false } = useResendActivationCode({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) {
        return;
      }

      setIsResendActivationCodeDialogOpen(false);

      handleToastSuccess({
        message: 'Your activation code has been sent to your email',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product: getProduct(),
  });

  const handleReturnToLogin = () => {
    setIsConfirmMfaCodeVisible(false);

    push('login').catch((error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    });
  };

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    if (getDomain() === LOCALHOST) {
      setHasLocalHostDomainSelector(true);
      return;
    }

    void handleGetGeladaProjectByDomain({
      domain: getDomain(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!selectedLocalHostDomain) {
      return;
    }

    const domain = getDomain();

    if (domain !== LOCALHOST) {
      return;
    }

    void handleGetGeladaProjectByDomain({
      domain: selectedLocalHostDomain,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocalHostDomain]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!isAuthenticationDataSetup) {
      handleToastError({
        error: new Error("The minimum credentials to login aren't set-up"),
        shouldUseFriendlyErrorMessage: true,
      });
      return;
    }

    const redirectAfterLoginUrl = getRedirectAfterLoginUrlCookie() ?? '';

    handleLoginRedirect({
      isCustomBuild: isProjectCustomBuild,
      isUserAProjectAdmin: isUserAProjectAdmin,
      projectWebsiteUrl,
      redirectAfterLoginUrl,
      redirectIfSingularOrgProjectUrl,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticationDataSetup) return;
    setIsAuthenticated(true);
  }, [isAuthenticationDataSetup]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setForgotPasswordUrl(getForgotPasswordUrl());
    setRegistrationUrl(getRegistrationUrl());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!(isDomainReady && storedDashboardUrl)) {
      return;
    }

    setDashboardUrl(String(storedDashboardUrl));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady, storedDashboardUrl]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setDashboardUrl(String(storedDashboardUrl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady, storedDashboardUrl]);

  const isLoading = isGetGeladaProjectByDomainLoading || isLoginLoading || isResendActivationCodeLoading;

  return (
    <Layout
      headerText={isConfirmMfaCodeVisible ? AUTHENTICATE : HEADER_TEXT}
      isPreloaderVisible={isLoading}
      pageDescription={!isConfirmMfaCodeVisible ? PAGE_DESCRIPTION : ''}
      title="Login"
      shouldRerender
    >
      {isConfirmMfaCodeVisible ? (
        <ConfirmMfaCodeForm
          isSubmitting={isLoading}
          onReturnToLoginClick={handleReturnToLogin}
          onSubmitCode={(code) => {
            const { email: login, password } = loginCredentials ?? {};

            void handleLogin({
              login,
              password: password ?? '',
              mfaCode: code,
              shouldIncludeUserMemberships: true,
              shouldIncludeUserMembershipsProject: true,
              shouldIncludeUserPictures: true,
            });
          }}
        />
      ) : (
        <div className={styles[`${blockCssId}__form`]}>
          {isPreloginVisible && (
            <PreLoginForm
              hasLocalHostDomainSelector={hasLocalHostDomainSelector}
              isSubmitting={isLoading}
              onLocalHostDomainChange={(value) => setSelectedLocalHostDomain(value)}
              onSubmit={({ email: login }) => {
                window.location.href = `/login?email=${encodeURIComponent(login)}`;
              }}
            />
          )}
          {!isPreloginVisible && (
            <LoginForm
              forgotPasswordUrl={forgotPasswordUrl}
              initialEmail={decodeURIComponent(String(initialEmail))}
              isSubmitting={isLoading}
              onLogin={({ email: login, password }) => {
                setLoginCredentials({
                  email: login,
                  password,
                });

                void handleLogin({
                  login,
                  password,
                  shouldIncludeUserMemberships: true,
                  shouldIncludeUserMembershipsProject: true,
                  shouldIncludeUserPictures: true,
                });
              }}
            />
          )}
          <Dialog
            isOpen={isResendActivationCodeDialogOpen}
            title="Activate your account"
            onClose={() => setIsResendActivationCodeDialogOpen(false)}
          >
            <p>Your email hasn&#39;t been activated, click below to resend activation</p>

            <br />

            <Button
              text="Resend activation code"
              onClick={() => {
                if (!loginCredentials) {
                  return;
                }

                const { email } = loginCredentials;

                void handleResendActivationCode({
                  email,
                });
              }}
            />
          </Dialog>

          {!isProjectCustomBuild && registrationUrl && getProduct() !== 'EKARDO' && (
            <div className={styles[`${blockCssId}__register-account-container`]}>
              <span>
                Not registered yet? <a href={registrationUrl}>Create an account</a>
              </span>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export { LoginPage };
