import { SidebarListProps } from './SidebarListItem.interfaces';

const SidebarList = ({children, hasCount = false, itemsLength = 0, text, threshold = 10 }: SidebarListProps) => {
  return (
    <>
      <ul className="c-sidebar-list">
        {children}
      </ul>
      {hasCount && itemsLength > threshold && (
        <div className="c-sidebar-list__count">
          {itemsLength}{' '}{text}
        </div>
      )}
    </>
  );
};

export { SidebarList };
