
import { Avatar, ButtonIconOnly, CaretDownIcon, FlexContainer, SettingsButton} from '@netfront/ui-library';
import cx from 'classnames';


import { SidebarListItemProps } from './SidebarListItem.interfaces';


const SidebarListItem = ({
  additionalClassNames = '',
  additionalButton,
  isSettingsButton = false,
  name = '',
  onClick,
  supportiveText = '',
  title,
  customComponent,
}: SidebarListItemProps) => {

  const interactionButton = onClick && (
    <>
      {
        isSettingsButton ? (
          <SettingsButton supportiveText={supportiveText} onClick={onClick} />
        ) : (
          <ButtonIconOnly
            additionalClassNames="h-rotate-right"
            icon={CaretDownIcon}
            isIconBorderVisible={false}
            text={supportiveText}
            onClick={onClick}
          />
        )
      }

    </>
  );

  return (
    <li className={cx('c-sidebar-list__item', additionalClassNames, {'c-sidebar-list__item--small': title})}>
      <div className="c-sidebar-list__item-content">
        {customComponent ? customComponent : (
          <>
            {title && <Avatar size="small" title={title} />}
            <span className="c-sidebar-list__item-label">{name}</span>
          </>
        )}
      </div>
      {
        additionalButton ? (
          <FlexContainer gap="2x-small">
            {interactionButton}
            {additionalButton}
          </FlexContainer>
        ) : <>{interactionButton}</>
      }
    </li>
  );
};

export { SidebarListItem };
