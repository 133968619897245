import { ControllerFieldState } from 'react-hook-form';

const getFormFieldErrorMessage = ({ error, invalid }: ControllerFieldState): string => {
  if (!(error && invalid)) {
    return '';
  }

  const { message = '' } = error;

  return message;
};

export { getFormFieldErrorMessage };
