import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { getBEMClassName } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS, FormFieldContainer, IPasswordValidation } from '@netfront/gelada-identity-library';
import { Button, Input, PasswordInput, Spacing } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';


import { LOGIN_FORM_CSS_IDENTIFIERS, LOGIN_FORM_INITIAL_VALUES } from './LoginForm.constants';
import { ILoginFormFields, LoginFormProps } from './LoginForm.interfaces';

import { getFormFieldErrorMessage } from '../../Forms.helpers';

const LoginForm = ({
  buttonText = 'Login',
  forgotPasswordUrl,
  isSubmitting,
  onLogin,
  passwordValidation,
  initialEmail,
}: LoginFormProps) => {
  const {
    button: buttonElementCssId,
    container: containerElementCssId,
    forgotPassword: forgotPasswordElementCssId,
    form: formElementCssId,
    link: linkCssId,
  } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    email: { id: emailId, label: emailLabel, validationErrorMessage: emailValidationErrorMessage },
    password: { id: passwordId, label: passwordLabel, maxLength: passwordMaxLength, minLength: passwordMinLength },
  } = FORM_FIELDS;

  const { block: blockCssId } = LOGIN_FORM_CSS_IDENTIFIERS;

  const { maxLength: maxPasswordLength = passwordMaxLength, minLength: minPasswordLength = passwordMinLength } =
    passwordValidation ?? ({} as IPasswordValidation);

  const defaultValues = { ...LOGIN_FORM_INITIAL_VALUES, email: initialEmail ?? LOGIN_FORM_INITIAL_VALUES.email };

  const { control, handleSubmit } = useForm<ILoginFormFields>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().label(emailLabel).email(emailValidationErrorMessage).required(),
        password: yup.string().label(passwordLabel).max(maxPasswordLength).min(minPasswordLength).required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  return (
    <form
      className={getBEMClassName(blockCssId, formElementCssId)}
      onSubmit={(event) => {
        void handleSubmit(({ email, password }) => {
          onLogin({
            email,
            password,
          });
        })(event);
      }}
    >
      <div className="c-form__body">
        <Spacing size="small">
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <Input
                additionalClassNames={getBEMClassName(blockCssId, emailId)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={emailId}
                isDisabled={isSubmitting}
                labelText={emailLabel}
                placeholder=""
                type="email"
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>

        <Controller
          control={control}
          name="password"
          render={({ field, fieldState }) => (
            <PasswordInput
              additionalClassNames={getBEMClassName(blockCssId, passwordId)}
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id={passwordId}
              isDisabled={isSubmitting}
              labelText={passwordLabel}
              placeholder=""
              isRequired
              {...field}
            />
          )}
        />
      </div>

      <div className="c-form__footer">
        <div className={getBEMClassName(blockCssId, `${buttonElementCssId}s-${containerElementCssId}`)}>
          {forgotPasswordUrl && (
            <FormFieldContainer
              css={{
                blockId: blockCssId,
                elementId: `${forgotPasswordElementCssId}-${linkCssId}-${containerElementCssId}`,
              }}
            >
              <a className={getBEMClassName(blockCssId, `${forgotPasswordElementCssId}-${linkCssId}`)} href={forgotPasswordUrl}>
                Forgot password?
              </a>
            </FormFieldContainer>
          )}

          <Button size="small" text={buttonText} type="submit"/>
        </div>
      </div>
    </form>
  );
};

// eslint-disable-next-line import/no-default-export
export { LoginForm };
