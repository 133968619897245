import { Select, Spacing } from '@netfront/ui-library';

import { LocalDomainSelectorProps } from './LocalDomainSelector.interfaces';

const LocalDomainSelector = ({ isVisible = false, labelText = 'Select a domain to test in', onChange }: LocalDomainSelectorProps) => {
  if (!isVisible) {
    return <></>;
  }

  return (
    <Spacing>
      <Select
        id="id_domain_selector"
        labelText={labelText}
        name="domainSelector"
        options={[
          {
            id: 0,
            name: 'Ekardo',
            value: 'ekardo.com',
          },
          {
            id: 1,
            name: 'Kanzi',
            value: 'kanzi.io',
          },
          {
            id: 2,
            name: 'Quickcodes',
            value: 'quickcodes.io',
          },
        ]}
        onChange={({ target: { value } }) => onChange(value)}
      />
    </Spacing>
  );
};

export { LocalDomainSelector };
