import { IPreLoginFormFields } from './PreLoginForm.interfaces';

const PRELOGIN_FORM_CSS_IDENTIFIERS = Object.freeze({
  block: 'c-login-form',
});

const PRELOGIN_FORM_INITIAL_VALUES: IPreLoginFormFields = {
  email: '',
};

export { PRELOGIN_FORM_CSS_IDENTIFIERS, PRELOGIN_FORM_INITIAL_VALUES };
