import React, { useContext, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useToast } from '@netfront/ekardo-content-library';
import { Dialog, GeneralTabIcon, SideBarTabSet, Spinner } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { UpsertOrganisationSidebarProps } from './UpsertOrganisationSidebar.interfaces';

import { UpsertOrganisationSidebarGeneralTab } from '../../../components/Tabs/Organisation/UpsertOrganisationSidebarGeneralTab';
import { INTERNAL_URLS } from '../../../constants';
import { UserContext } from '../../../context';
import { useDeleteGeladaOrganisation } from '../../../hooks/useDeleteGeladaOrganisation';
import { useLastVisitedOrganisationCookie } from '../../../utils';

const UpsertOrganisationSidebar = ({
  selectedOrganisation,
  onCreateOrganisation,
  onDeleteOrganisation,
  onUpdateOrganisation,
  isSideBarOpen,
  handleOpenCloseSideBar,
  prefix = 'dashboard',
  onDeleteAsset,
}: UpsertOrganisationSidebarProps) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { push } = useRouter();
  const { removeUserLastVisitedOrganisation } = useLastVisitedOrganisationCookie();

  const { handleToastError, handleToastSuccess } = useToast();

  const { handleDeleteGeladaOrganisation, isLoading: isDeleteGeladaOrganisationLoading = false } = useDeleteGeladaOrganisation({
    onCompleted: ({ isCompleted }: { isCompleted: boolean }) => {
      setIsDeleteDialogOpen(false);

      if (isCompleted) {
        removeUserLastVisitedOrganisation(String(user?.id ?? ''));
        if (onDeleteOrganisation) {
          onDeleteOrganisation(String(selectedOrganisation?.key));
        } else {
          push(INTERNAL_URLS.dashboard).catch((error) => {
            handleToastError({
              error,
              shouldUseFriendlyErrorMessage: true,
            });
          });
        }
      }

      handleToastSuccess({
        message: `Organisation successfully deleted`,
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleDelete = () => {
    void handleDeleteGeladaOrganisation({
      organisationKey: String(selectedOrganisation?.key),
    });
  };

  return (
    <>
      <SideBarTabSet
        defaultActiveTabId="id_account_general_tab"
        handleOpenCloseSideBar={() => {
          handleOpenCloseSideBar();
        }}
        hasViewPadding={false}
        isSideBarOpen={isSideBarOpen}
        tabs={[
          {
            id: 'id_account_general_tab',
            label: 'General',
            view: () => {
              return (
                <UpsertOrganisationSidebarGeneralTab
                  organisation={selectedOrganisation}
                  prefix={prefix}
                  onCancel={handleOpenCloseSideBar}
                  onCreateOrganisation={onCreateOrganisation}
                  onDelete={() => setIsDeleteDialogOpen(true)}
                  onDeleteAsset={onDeleteAsset}
                  onUpdateOrganisation={onUpdateOrganisation}
                />
              );
            },
            icon: GeneralTabIcon,
          },
        ]}
        hideSideBarButtons
      />
      {selectedOrganisation && (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete ${String(selectedOrganisation.name)}?`}
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
        />
      )}
      <Spinner isLoading={isDeleteGeladaOrganisationLoading} />
    </>
  );
};

export { UpsertOrganisationSidebar };
