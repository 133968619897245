
import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IGeladaOrganisation, useCreateGeladaOrganisation, useUpdateGeladaOrganisation } from '@netfront/gelada-identity-library';
import { SidebarButtons, Input, Textarea, ImageUpload, Spinner } from '@netfront/ui-library';

import { SidebarContainer } from 'components/Shared';

import { OrganisationSidebarGeneralTabProps } from './UpsertOrganisationSidebarGeneralTab.interfaces';

import { PermissionContext } from '../../../../context';
import { useDeleteAsset, useToast } from '../../../../hooks';
import { handleImageUpload, useUpdateAccessToken, toKebabCase } from '../../../../utils';


const UpsertOrganisationSidebarGeneralTab = ({ isLoading = false, organisation, onCreateOrganisation, onUpdateOrganisation, onCancel, onDelete, onDeleteAsset, prefix = 'dashboard'}: OrganisationSidebarGeneralTabProps) => {
  const { hasPermission } = useContext(PermissionContext);

  const [name, setName] = useState<string>('');
  const [key, setKey] = useState<string>('');
  const [initialLogoUrl, setInitialLogoUrl] = useState<string | undefined>();
  const [assetId, setAssetId] = useState<string>();
  const [description, setDescription] = useState<string>('');
  const [logo, setLogo] = useState<File>();
  const [hasEditPermission, setHasEditPermission] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleToastError, handleToastSuccess } = useToast();
  const { updateRefreshToken } = useUpdateAccessToken();

  const handleOnCreateOrganisation = (returnedOrganisation: IGeladaOrganisation) => {
    updateRefreshToken(() => {
      setLogo(undefined);
      if (onCreateOrganisation) onCreateOrganisation(returnedOrganisation);
      setIsSubmitting(false);
    });
  };


  const { handleCreateGeladaOrganisation } = useCreateGeladaOrganisation({

    onCompletedAsync: async ({ organisation: returnedOrganisation }) => {
      if (logo) {
        await handleImageUpload({
          logo: returnedOrganisation.logo,
          uploadedFile: logo,
          callBack: () => {
            handleOnCreateOrganisation(returnedOrganisation);
          }
        })
      } else {
        handleOnCreateOrganisation(returnedOrganisation);
      };

      handleToastSuccess({
        message: `Organisation ${returnedOrganisation.name} created successfully`,
      });
    },
    onError: (error: ApolloError) => {
      setIsSubmitting(false);
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateGeladaOrganisation } = useUpdateGeladaOrganisation({
    onCompletedAsync: async ({ organisation: returnedOrganisation }) => {
      if (logo) {
        await handleImageUpload({
          logo: returnedOrganisation.logo,
          uploadedFile: logo,
          callBack: () => {
            setLogo(undefined);
            if (onUpdateOrganisation) onUpdateOrganisation(returnedOrganisation);
          }
        })
      } else {
        if (onUpdateOrganisation) onUpdateOrganisation(returnedOrganisation);
      };

      setIsSubmitting(false);

      handleToastSuccess({
        message: `Organisation ${returnedOrganisation.name} updated successfully`,
      });
    },
    onError: (error: ApolloError) => {
      setIsSubmitting(false);
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleDeleteAsset } = useDeleteAsset({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        if (onDeleteAsset) onDeleteAsset(organisation?.key ?? '');
        setAssetId(undefined);
      }
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const onSubmit = () => {
    setIsSubmitting(true);
    if (organisation) {
      const { id } = organisation;

      void handleUpdateGeladaOrganisation({
        shouldIncludeOrganisationLogo: true,
        description: String(description),
        id,
        name,
        tag: '',
        url: '',
        logo: logo ? {
          contentType: logo.type,
          fileName: logo.name,
          fileSizeInBytes: logo.size,
          type: 'ORGANISATION_LOGO'
        }: undefined,
      });

    } else {
      void handleCreateGeladaOrganisation({
        shouldIncludeOrganisationLogo: true,
        description: String(description),
        name,
        key: key,
        logo: logo ? {
          contentType: logo.type,
          fileName: logo.name,
          fileSizeInBytes: logo.size,
          type: 'ORGANISATION_LOGO'
        }: undefined,
      });
    }
  }


  useEffect(() => {
    if (!organisation) return;

    setName(String(organisation.name))
    setKey(String(organisation.key));
    setDescription(String(organisation.description));
    setAssetId(String(organisation.logo?.assetId ?? ''));
    setInitialLogoUrl(organisation.logo?.presignedUrl ?? undefined);
    setHasEditPermission(hasPermission(String(organisation.id), 'EDIT'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  return (
    <SidebarContainer>
      <form onSubmit={(e) =>{
        e.preventDefault();
        onSubmit();
      }}>
        <Input
          id={`id_account_${prefix}_name`}
          isDisabled={isSubmitting}
          isLoading={isLoading}
          isReadOnly={!hasEditPermission}
          labelText="Title"
          maxLength={50}
          name="name"
          tooltipText="Give your account a title"
          type="text"
          value={name}
          isLabelSideBySide
          isRequired
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const {
              target: { value },
            } = event;
            setName(value);
            if (!organisation) setKey(toKebabCase(String(value)));
          }}
        />

        <Input
          id={`id_account_${prefix}_key`}
          isDisabled={!!organisation || isSubmitting}
          isLoading={isLoading}
          isReadOnly={!hasEditPermission}
          labelText="Account key"
          maxLength={50}
          name="key"
          tooltipText="Give your account a key"
          type="text"
          value={key}
          isLabelSideBySide
          isRequired
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const {
              target: { value },
            } = event;
            setKey(toKebabCase(String(value)));
          }}
        />

        <Textarea
          id={`id_account_${prefix}_description`}
          isDisabled={isSubmitting}
          isLoading={isLoading}
          isReadOnly={!hasEditPermission}
          labelText="Account description"
          maxLength={950}
          name="description"
          tooltipText="Give your account a description"
          value={description}
          isLabelSideBySide
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            const {
              target: { value },
            } = event;
            setDescription(String(value));
          }}
        />

        <ImageUpload
          id={`id_account_${prefix}_logo`}
          initialUrl={initialLogoUrl}
          isDisabled={isSubmitting}
          isLoading={isLoading}
          isReadOnly={!hasEditPermission}
          labelText="Account logo"
          name="logoUrl"
          tooltipText="Give your account a logo"
          isLabelSideBySide
          onDelete={assetId ? () => {
            void handleDeleteAsset({
              id: assetId,
            });
          }: undefined}
          onDrop={setLogo}
        />

        <SidebarButtons
          buttonSize="xs"
          isSaveButtonDisabled={isSubmitting || !hasEditPermission}
          submitButtonText="Save"
          onCancel={onCancel}
          onDelete={organisation && hasEditPermission ? onDelete : undefined}
          onSaveButtonType="submit"
        />
        <Spinner isLoading={isSubmitting} />
      </form>
    </SidebarContainer>
  );
};



export { UpsertOrganisationSidebarGeneralTab };
